<style lang="less">
.home {
	position: relative;
	.home-contain {
		.title {
			color: #252525;
			font-size: 36px;
			font-weight: 700;
			margin: 30px 0;
		}
		.el-button {
			background-color: #46a853;
			color: #fff;
			font-weight: 600;
			margin: 20px 0;
			// padding: 16px 20px;
			padding: 14px 24px;
			font-size: 16px;
			&:hover {
				background-color: #424242;
				border-color: #000;
			}
		}
		.first {
			.content {
				color: #46a853;
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 20px;
			}
			.video_box {
				padding-left: 0.2rem;
				position: relative;
				.video {
					margin-top: 20px;
					margin-bottom: 80px;
					object-fit: cover;
				}
				.playButton {
					width: 100px;
					height: 70px;
					line-height: 70px;
					background: rgba(0, 0, 0, 0.65);
					border-radius: 18px;
					font-size: 50px;
					color: #fff;
					position: absolute;
					top: 40%;
					left: 50%;
					margin-left: -50px;
					cursor: pointer;
				}
			}
			iframe {
				margin: 50px 0;
				video {
					width: 1000px !important;
					height: 600px !important;
				}
			}
		}
		.second {
			background: url('https://bestfulfill.com.cn/bestfulfillImages/pattern.jpg');
			padding: 80px 0;
			.littleTitle {
				color: #46a853;
				font-size: 16px;
				font-weight: 600;
			}
			.title {
				margin-top: 0 !important;
			}
			.el-row {
				padding: 0 20%;
				margin: 0 auto;
				.el-col {
					.gutter {
						padding: 50px 0;
						border-radius: 20px;
						background: #fff;
						margin-bottom: 20px;
						box-shadow: 0px 0px 40px 0px rgba(222.75, 222.75, 222.75, 0.5);
						height: 360px;
						&:hover {
							border-bottom: 2px solid #46a853;
							box-shadow: 0px 0px 12px 0px rgba(70, 168, 83, 0.17);
						}
						a:hover {
							color: #252525;
						}
						.second-contain {
							margin: 0 auto;
							width: 80%;
							.imgBg {
								width: 156px;
								height: 156px;
								line-height: 156px;
								border-radius: 50%;
								background: linear-gradient(
									to left top,
									rgba(70, 168, 83, 0.39),
									rgba(70, 168, 83, 0.34),
									rgba(70, 168, 83, 0.14),
									rgba(70, 168, 83, 0.08),
									#fff
								);
								margin: 0 auto;
							}
							.content1 {
								font-size: 20px;
								font-weight: 600;
								margin: 10px 0;
							}
							.content2 {
								color: #7a7a7a;
							}
						}
					}
				}
			}
		}
		.third {
			background: #f7f7f7;
			padding: 80px 0;
			.el-row {
				padding: 0 20%;
				margin: 0 auto;
				.el-col {
					.gutter {
						padding: 30px 0;
						background: #fff;
						margin-bottom: 20px;
						.third-contain {
							margin: 0 auto;
							width: 80%;
							.imgBg {
								width: 96%;
								border-radius: 10px;
								margin: 0 auto;
							}
							.content1 {
								font-size: 20px;
								font-weight: 600;
								margin: 10px 0;
							}
							.content2 {
								color: #7a7a7a;
							}
						}
					}
				}
			}
		}
		.fourth {
			.el-carousel {
				.el-carousel__container {
					height: 380px;
					.el-carousel__item {
						.el-row {
							margin: 0 !important;
							padding-top: 10px;
							.el-col {
								.gutter {
									padding: 20px 0;
									background: #f5f5f5;
									margin-bottom: 20px;
									.fourth-contain {
										margin: 0 auto;
										width: 80%;
										.imgBg {
											width: 100%;
											margin: 0 auto;
											position: relative;
											// img {
											// 	width: 316px;
											// 	height: 260px;
											// }
											span {
												width: 90px;
												height: 50px;
												border-radius: 5px;
												background: rgba(85, 150, 80, 0.8);
												position: absolute;
												left: 50%;
												top: 50%;
												margin-left: -45px;
												margin-top: -25px;
												.el-icon-caret-right {
													font-size: 36px;
													color: #fff;
													position: absolute;
													left: 30%;
													top: 12%;
												}
											}
										}
										.name {
											font-size: 20px;
											font-weight: 600;
											margin: 10px 0;
										}
									}
								}
							}
						}
					}
				}
			}
			.el-carousel__button {
				width: 8px;
				height: 8px;
				border-radius: 50%;
			}
			.el-carousel__indicators--outside button {
				background: #333;
			}
			.carouselpc {
				margin: 0 auto;
				width: 70%;
			}
			.carouselapp {
				margin: 0 auto;
				width: 100%;
				.el-carousel__container {
					height: 325px;
				}
			}
		}
		.fifth {
			background: #ffffff;
			padding: 80px 0;
		}
	}
	.modalBg {
		background: rgba(0, 0, 0, 0.7);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 120;
		width: 100%;
		height: 100%;
		.video_box {
			position: fixed;
			left: 40%;
			top: 10%;
			video {
				height: 800px;
			}
		}
		.video_box_Claire {
			position: fixed;
			left: 20%;
			top: 10%;
			video {
				height: 800px;
			}
		}
		span {
			position: fixed;
			right: 30px;
			top: 30px;
			z-index: 20;
			font-size: 30px;
			cursor: pointer;
			color: #a7a7a7;
		}
	}
	@media (max-width: 767px) {
		.title {
			font-size: 22px !important;
		}
		.el-row {
			padding: 0 20px !important;
		}
		.first {
			.video_box {
				padding: 0 10px !important;
				.video {
					width: 100%;
				}
			}
			.playButton {
				top: 30% !important;
			}
		}
		.second {
			.el-row {
				padding: 0 10px !important;
				.el-col {
					padding-left: 5px !important;
					padding-right: 5px !important;
					.imgBg {
						width: 70px !important;
						height: 70px !important;
						line-height: 70px !important;
						img {
							width: 26px;
							height: 26px;
						}
					}
					.content1 {
						font-size: 15px !important;
					}
					.content2 {
						font-size: 13px;
					}
					.gutter {
						height: 274px !important;
					}
				}
			}
		}
		.carouselpc {
			display: none;
		}
		.carouselapp {
			display: block;
		}
		.modalBg {
			.video_box {
				left: 15% !important;
				top: 15% !important;
				video {
					height: 500px !important;
				}
			}
			.video_box_Claire {
				left: 0;
				top: 30%;
				video {
					width: 96%;
					height: auto;
				}
			}
		}
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		.first {
			.video_box {
				padding: 0 10px !important;
				.video {
					width: 100%;
				}
			}
		}
		.carouselpc {
			display: none;
		}
		.carouselapp {
			display: block;
		}
		.modalBg {
			.video_box {
				left: 30% !important;
				top: 15% !important;
				video {
					height: 500px !important;
				}
			}
			.video_box_Claire {
				left: 0;
				top: 30%;
				video {
					width: 96%;
					height: auto;
				}
			}
		}
	}
	@media (min-width: 1024px) {
		.first {
			.video_box {
				.video {
					width: 1120px;
					height: 630px;
				}
			}
		}
		.carouselpc {
			display: block;
		}
		.carouselapp {
			display: none;
		}
	}
	.track {
		width: 350px;
		padding: 35px;
		border-radius: 5px;
		background: #000;
		opacity: 0.8;
		position: fixed;
		right: 0;
		top: 35%;
		z-index: 9;
		color: #fff;
		text-align: left;
		.content {
			color: #c8c8c8;
			margin-top: 20px;
			font-size: 16px;
		}
		.little-title {
			color: #737373;
			font-size: 18px;
			margin-top: 6px;
		}
		.el-form-item__content,
		.el-input {
			width: 100%;
		}
		.el-button--success {
			background-color: #46a853;
			border-color: #46a853;
			color: #eee;
			width: 100%;
		}
	}
}
</style>
<template>
	<div class="home">
		<div class="home-contain">
			<!-- 第一部分 -->
			<div class="first">
				<div class="title">
					Professional Dropshipping Sourcing and Fulfillment Agent
				</div>
				<div class="content">
					Bestfulfill help dropshippers with order fulfillment from product
					sourcing, shipping, branding to customized package.
				</div>
				<div class="video_box">
					<video
						class="video"
						ref="playVideo"
						:src="videoURL + 'videoplayback.mp4'"
						:poster="videoURL + 'videoplay.jpg'"
						controls="controls"
						preload="none"
						webkit-playsinline="true"
						playsinline="true"
						loop
						@play="onPlay"
						@pause="onPause"
					>
						<source :src="videoURL + 'videoplayback.mp4'" type="video/mp4" />
					</video>
					<div class="playButton" ref="playButton" @click="playButton">
						<el-icon class="el-icon-caret-right"></el-icon>
					</div>
				</div>
				<!-- <iframe
					width="1000"
					height="600"
					ref="iframeVideo"
					:src="imgURL + 'videoplayback.mp4'"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe> -->
			</div>
			<!-- 第二部分 -->
			<div class="second">
				<div class="littleTitle">SOLUTIONS</div>
				<div class="title">What We do</div>
				<el-row :gutter="20">
					<el-col :xs="12" :sm="12" :lg="8">
						<div class="gutter">
							<div class="second-contain">
								<div class="imgBg">
									<img :src="imgURL + 'secondImg1.png'" alt="" />
								</div>
								<div class="content1">Dropshipping Order Fulfillment</div>
								<div class="content2">
									Professional Sourcing Products, Packing, Shipping's Order
									Fulfillment Solution
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :lg="8">
						<div class="gutter">
							<x-router-link to="/china-sourcing-agent">
								<div class="second-contain">
									<div class="imgBg">
										<img :src="imgURL + 'secondImg2.png'" alt="" />
									</div>
									<div class="content1">Product Sourcing</div>
									<div class="content2">
										Dropshipping Thousands of Profitable Chinese Products from
										Aliexpress,1688, taobao
									</div>
								</div>
							</x-router-link>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :lg="8">
						<div class="gutter">
							<x-router-link to="/3pl-fulfillment">
								<div class="second-contain">
									<div class="imgBg">
										<img :src="imgURL + 'secondImg3.png'" alt="" />
									</div>
									<div class="content1">Third-Party Logistics（3PL）</div>
									<div class="content2">
										Bestfulfill choose the most fastest and cost-effective
										shipping lines to best match your...
									</div>
								</div>
							</x-router-link>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :lg="8">
						<div class="gutter">
							<x-router-link to="/product-branding">
								<div class="second-contain">
									<div class="imgBg">
										<img :src="imgURL + 'secondImg4.png'" alt="" />
									</div>
									<div class="content1">Branding & Customized Service</div>
									<div class="content2">
										We offer a full solution for customized branding package for
										your Brand
									</div>
								</div>
							</x-router-link>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :lg="8">
						<div class="gutter">
							<div class="second-contain">
								<div class="imgBg">
									<img :src="imgURL + 'secondImg5.png'" alt="" />
								</div>
								<div class="content1">Bulk Inventory Storage</div>
								<div class="content2">
									With 3000+ m2 warehousing area,we divided it into office
									stocking and packing area
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :lg="8">
						<div class="gutter">
							<x-router-link to="/after-sale-service">
								<div class="second-contain">
									<div class="imgBg">
										<img :src="imgURL + 'secondImg6.png'" alt="" />
									</div>
									<div class="content1">After-Sales Service</div>
									<div class="content2">
										We offer free resend or refund if your orders is defective
										or lost, feel free to let us know
									</div>
								</div>
							</x-router-link>
						</div>
					</el-col>
				</el-row>
				<x-router-link to="/our-services">
					<el-button type="success">View All Services</el-button>
				</x-router-link>
			</div>
			<!-- 第三部分 -->
			<div class="third">
				<div class="title">Our Team</div>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :lg="6">
						<div class="gutter">
							<div class="third-contain">
								<div class="imgBg">
									<img :src="imgURL + 'Sam.jpg'" alt="" />
								</div>
								<div class="content1">Shun (Sam) Hu</div>
								<div class="content2">Operations director</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :lg="6">
						<div class="gutter">
							<div class="third-contain">
								<div class="imgBg">
									<img :src="imgURL + 'Jayden.jpg'" alt="" />
								</div>
								<div class="content1">Zhennan (Jayden) Lu</div>
								<div class="content2">Business Director</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :lg="6">
						<div class="gutter">
							<div class="third-contain">
								<div class="imgBg">
									<img :src="imgURL + 'Taylor.jpg'" alt="" />
								</div>
								<div class="content1">TAYLOR</div>
								<div class="content2">Manager</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :lg="6">
						<div class="gutter">
							<div class="third-contain">
								<div class="imgBg">
									<img :src="imgURL + 'Aileen.jpg'" alt="" />
								</div>
								<div class="content1">AILEEN</div>
								<div class="content2">Manager</div>
							</div>
						</div>
					</el-col>
					<!-- <el-col :span="6">
						<div class="gutter">
							<div class="third-contain">
								<div class="imgBg">
									<img :src="imgURL + 'Rae.jpg'" alt="" />
								</div>
								<div class="content1">RAE</div>
								<div class="content2">Manager</div>
							</div>
						</div>
					</el-col> -->
				</el-row>
				<x-router-link to="/our-team">
					<el-button type="success">View More</el-button>
				</x-router-link>
			</div>
			<!-- 第四部分 -->
			<div class="fourth">
				<div class="title">Testimonials</div>
				<div class="carouselpc">
					<el-carousel :interval="3000" indicator-position="outside">
						<el-carousel-item>
							<div class="one">
								<el-row :gutter="20">
									<el-col
										:span="8"
										v-for="(item, index) in carouselList1"
										:key="index"
									>
										<div class="gutter">
											<div class="fourth-contain">
												<div class="imgBg">
													<img :src="item.image" alt="" />
													<span @click="playVideo(item.poster, item.url)">
														<el-icon class="el-icon-caret-right"></el-icon>
													</span>
												</div>
												<div class="name">{{ item.name }}</div>
											</div>
										</div>
									</el-col>
								</el-row>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="two">
								<el-row :gutter="20">
									<el-col
										:span="8"
										v-for="item in carouselList2"
										:key="item.name"
									>
										<div class="gutter">
											<div class="fourth-contain">
												<div class="imgBg">
													<img :src="item.image" alt="" />
													<span @click="playVideo(item.poster, item.url)">
														<el-icon class="el-icon-caret-right"></el-icon>
													</span>
												</div>
												<div class="name">{{ item.name }}</div>
											</div>
										</div>
									</el-col>
								</el-row>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="three">
								<el-row :gutter="20">
									<el-col
										:span="8"
										v-for="item in carouselList3"
										:key="item.name"
									>
										<div class="gutter">
											<div class="fourth-contain">
												<div class="imgBg">
													<img :src="item.image" alt="" />
													<span @click="playVideo(item.poster, item.url)">
														<el-icon class="el-icon-caret-right"></el-icon>
													</span>
												</div>
												<div class="name">{{ item.name }}</div>
											</div>
										</div>
									</el-col>
								</el-row>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="carouselapp">
					<el-carousel :interval="3000" indicator-position="outside">
						<el-carousel-item
							v-for="(item, index) in carouselList1"
							:key="index"
						>
							<div class="one">
								<el-row :gutter="20">
									<el-col :span="24">
										<div class="gutter">
											<div class="fourth-contain">
												<div class="imgBg">
													<img :src="item.image" alt="" />
													<span @click="playVideo(item.poster, item.url)">
														<el-icon class="el-icon-caret-right"></el-icon>
													</span>
												</div>
												<div class="name">{{ item.name }}</div>
											</div>
										</div>
									</el-col>
								</el-row>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<!-- 第五部分 -->
			<div class="fifth">
				<EvaluateList />
			</div>
		</div>
		<!-- 遮罩层 -->
		<div class="modalBg" v-if="isShow">
			<span @click="close">X</span>
			<div
				:class="
					videourl == imgURL + 'claire.mp4' ? 'video_box_Claire' : 'video_box'
				"
			>
				<video
					class="video"
					:src="videourl"
					:poster="videoposter"
					controls="controls"
					webkit-playsinline="true"
					playsinline="true"
					preload="none"
					loop
				>
					<source :src="videourl" type="video/mp4" />
				</video>
			</div>
		</div>
		<!-- 17track -->
		<div class="track">
			<h3>Check your way-bill</h3>
			<div class="content">To get the latest information of your way-bill.</div>
			<el-form
				:model="inputValidateForm"
				ref="inputValidateForm"
				label-width="0"
				class="demo-ruleForm"
			>
				<el-form-item
					prop="code"
					:rules="[{ required: true, message: 'This field is required.' }]"
				>
					<div class="little-title">Document Code</div>
					<el-input
						v-model="inputValidateForm.code"
						placeholder="Enter Your Document Code"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="success" @click="submitForm('inputValidateForm')">
						Check
					</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import EvaluateList from '@/components/evaluateList.vue';

export default {
	name: 'HomeView',
	components: {
		EvaluateList,
	},
	data() {
		return {
			isShow: '',
			carouselList1: [
				{
					image: process.env.VUE_APP_IMG_URL + 'home/bahram-qazi.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/bahram-poster.jpg',
					name: 'Bahram qazi',
					url: process.env.VUE_APP_IMG_URL + 'home/bahram-qazi.mp4',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/claire.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/claire-poster.jpg',
					name: 'Claire',
					url: process.env.VUE_APP_IMG_URL + 'home/claire.mp4',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/flore.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/flore-poster.jpg',
					name: 'Michele Burke',
					url: process.env.VUE_APP_IMG_URL + 'home/flore.mp4',
				},
			],
			carouselList2: [
				{
					image: process.env.VUE_APP_IMG_URL + 'home/claire.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/claire-poster.jpg',
					name: 'Claire',
					url: process.env.VUE_APP_IMG_URL + 'home/claire.mp4',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/flore.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/flore-poster.jpg',
					name: 'Michele Burke',
					url: process.env.VUE_APP_IMG_URL + 'home/flore.mp4',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/bahram-qazi.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/bahram-poster.jpg',
					name: 'Bahram qazi',
					url: process.env.VUE_APP_IMG_URL + 'home/bahram-qazi.mp4',
				},
			],
			carouselList3: [
				{
					image: process.env.VUE_APP_IMG_URL + 'home/flore.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/flore-poster.jpg',
					name: 'Michele Burke',
					url: process.env.VUE_APP_IMG_URL + 'home/flore.mp4',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/bahram-qazi.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/bahram-poster.jpg',
					name: 'Bahram qazi',
					url: process.env.VUE_APP_IMG_URL + 'home/bahram-qazi.mp4',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/claire.jpg',
					poster: process.env.VUE_APP_IMG_URL + 'home/claire-poster.jpg',
					name: 'Claire',
					url: process.env.VUE_APP_IMG_URL + 'home/claire.mp4',
				},
			],
			videourl: '',
			videoposter: '',
			imgURL: process.env.VUE_APP_IMG_URL + 'home/',
			videoURL: process.env.VUE_APP_IMG_URL,
			inputValidateForm: {
				code: '',
			},
		};
	},
	created() {},
	mounted() {
		// console.log(this.$refs['iframeVideo']);
	},
	methods: {
		close() {
			this.isShow = '';
		},
		playVideo(poster, url) {
			this.isShow = true;
			this.videoposter = poster;
			this.videourl = url;
		},
		playButton() {
			this.$refs['playVideo'].play();
			this.$refs['playButton'].style.display = 'none';
		},
		onPlay() {
			this.$refs['playButton'].style.display = 'none';
		},
		onPause() {
			this.$refs['playButton'].style.display = 'block';
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					window.open(
						'http://1.14.193.186:8082/en/trackIndex.htm?documentCode=' +
							this.inputValidateForm.code,
						'_blank'
					);
					// 使用一个动态生成的表单，提交 POST 请求
					// let form = document.createElement('form');
					// form.method = 'POST';
					// form.action =
					// 	'http://1.14.193.186:8082/en/trackIndex.htm?documentCode=' +
					// 	this.inputValidateForm.code; // 表单提交地址

					// // 创建隐藏输入，传递表单数据
					// let input1 = document.createElement('input');
					// input1.type = 'hidden';
					// input1.name = 'code';
					// input1.value = this.inputValidateForm.code;
					// form.appendChild(input1);

					// document.body.appendChild(form);
					// form.submit(); // 提交表单，触发 POST 请求
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
	},
};
</script>
